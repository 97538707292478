import React from 'react';

export function Home() {
    return (
      <div>
        <h1>Ethan Yu</h1>
        <h2>Welcome!</h2>
        <p>Website is in progress, but feel free to look around!</p>
      </div>
    )
}

export default Home;